body > .alert
  margin-bottom: 0

.alert
  border-radius: 0
  border: none
  padding: 1rem
  margin-bottom: 0
  p:last-of-type
    margin-bottom: 0

  &.alert-success
    color: $darkgreen
    background-color: lighten($green, 50%)

  &.alert-warning
    color: $darkyellow
    background-color: $yellow

  &.alert-info
    color: $darkturqoisish
    background-color: $turquoisish

  &.alert-danger
    color: $white
    background-color: $lightred

  &.alert-danger-form-submit
    padding: 50px
    font-size: 20px   

  &.alert-success-form-submit
    padding: 50px
    border-bottom: 5px solid $darkgreen
    font-size: 20px    
    p
      span 
        font-size: 25px      