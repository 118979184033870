nav#support-in-page-nav
  @include media-breakpoint-down(md)
    display: none

  text-align: center
  margin: $margin 10px $margin 0
  min-height: 30rem
  max-height: 80vh
  &::-webkit-scrollbar
    width: 0 !important
  overflow-y: scroll
  overflow-y: -moz-scrollbars-none
  -ms-overflow-style: none
  position: relative
  padding-bottom: 2rem
  &:before
    content: ' '
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 1px
    background-image: linear-gradient(to bottom, rgba($default, .3), rgba($default, 0))

  a.list-group-item
    color: $default
    text-decoration: none
    text-align: right
    background-color: transparent
    border: 0
    border-color: $green
    padding: .3rem 1.25rem
    font-size: 15px
    +transition()
    &.active
      border-right: $margin/2 solid $green
    &:hover
      background-color: rgba($green, .2)
    &.subheading
      font-size: 14px
      color: rgba($default,.8)
      &.active
        border-right: $margin/4 solid $green

#hilfe-content
  .sticky-top
    position: -webkit-sticky
    top: 10px
    z-index: 50
  _:-ms-lang(x), .sticky-top
      max-width: 350px

div.nav-helper
  &:after
    position: absolute
    bottom: 0
    display: block
    content: ""
    background: linear-gradient(to top, $lightgrey, transparent)
    width: 100%
    height: 2rem
