.latest
  +clearfix
  .cardContent
    @include media-breakpoint-down(sm)
      display: block !important
  .tableContent
    @include media-breakpoint-down(sm)
      display: none !important
  h3.line
    margin-top: $margin*2
  .row.row-eq-height
    .item
      margin-bottom: $margin*2

      a
        color: $default
        text-decoration: none

      .card
        border-radius: 0
        border: none
        height: 100%

      img
        height: 100%
        width: 100%

      .card-footer
        padding: 0 0 .75rem 0
        background-color: transparent
        border-top: 0
        margin: $margin 0
        color: $grey

        .date
          float: left

        .card-footer-link
          float: right

        .comments
          float: right
          @include media-breakpoint-down(md)
            float: left
            margin-right: 1rem


      .badge
        position: absolute
        top: 16px
        right: 0
        padding: 1rem
        border-radius: 0
        z-index: 1

        &.badge-webinars
          background-color: $green
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-seminars
          background-color: $trafficlightred
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-ontour
          background-color: $black
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-other
          background-color: $grey
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-tag
          background-color: $default
          text-transform: uppercase
          font-weight: 500
          color: $white
          top: 49px

  .grid-item
    float: left
    margin-bottom: $margin
    width: calc((100% - 40px) / 3)
    @media (max-width: 768px)
      width: calc((100% - 40px) / 2)
    @media (max-width: 600px)
      width: calc((100% - 20px) / 2)

    a
      color: $default
      text-decoration: none

    .card
      border-radius: 0
      position: relative
      border: none

    img
      height: 100%
      width: 100%

    .card-footer
      padding: 0
      background-color: transparent
      border-top: 0
      margin: $margin 0
      color: $grey

      .date
        float: left

      .comments
        float: right
        @include media-breakpoint-down(md)
          float: left
          margin-right: 1rem


    .badge
      position: absolute
      top: 16px
      right: 0
      padding: 1rem
      border-radius: 0
      z-index: 1

      &.badge-webinars
        background-color: $green
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-seminars
        background-color: $trafficlightred
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-ontour
        background-color: $black
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-other
        background-color: $grey
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-tag
        background-color: $default
        text-transform: uppercase
        font-weight: 500
        color: $white
        top: 49px
        
  h3.line
    margin-top: 0
.tableContent
  display: none
  padding: 15px
  overflow-x: hidden
  .event-table-href
    text-align: center
    i 
      font-size: 24px
  th
    background-color: #DBDBDB
    font-size: 13px

  th.tablesorter-header
    background-image: url(/build/assets/img/sort.png)
    background-position: center right 1.25rem
    background-color: #41b496
    &:hover
      background-color: #7ACBB6
    color: $white
    font-size: 18px
    background-size: 10px
    padding: 20px
    background-repeat: no-repeat
    &.th-mehr
      min-width: 95px
    &.th-zeil
      min-width: 145px  
  th.tablesorter-headerAsc
    background-image: url(/build/assets/img/sort_down.png) !important


  th.tablesorter-headerDesc
    background-image: url(/build/assets/img/sort_up.png) !important

  th.sorter-false
    background-image: unset
  tr
    height: 60px
    &.as-link
      cursor: pointer
      &:hover
        background-color: #bfbfbf
        td 
          background-color: #bfbfbf
    td
      vertical-align: middle
      padding: 1.25rem !important
      &.as-link
        cursor: pointer
      img.product-logo
        height: 14px
.col-hx11
  padding-right: 0   
  @include media-breakpoint-down(md)
    padding-right: 15px   
.switchViewTable, .switchViewCard
  @include media-breakpoint-down(sm)
    display: none
  float: right
  margin-right: 5px
  i 
    font-size: 25px


.switchView
  top: 3rem
  a
    color: black
    &.activeSwitch
      color: #48c0b1

  