.support-overview
  margin-bottom: 20px
  padding: 0 0 0 20px

  .row
    margin: 0

    .col-md-6
      padding: 0 20px 20px 0

      .item
        background:
          color: $white
          image: url('../../../img/hilfe-verlag-icon.svg')
          position: -2rem -6rem
          repeat: no-repeat
          size: 30%
        border: none
          radius: 0
        color: $default
        display: block
        height: 100%
        text-decoration: none
        min-height: 215px
        padding: 2.4rem

        &.buchhandel-icon
          background:
            image: url('../../../img/hilfe-buchhandel-icon.svg')
        &.empfehlungen
          background:
            image: url('../../../img/hilfe-empfehlungen-icon.svg')
        &.datenanlieferung
          background:
            image: url('../../../img/hilfe-datenanlieferung-icon.svg')
        &.datenbezug
          background:
            image: url('../../../img/hilfe-datenbezug-icon.svg')
        &.zis
          background:
            image: url('../../../img/hilfe-zis-icon.svg')
        &.neu
          background:
            image: url('../../../img/hilfe-neu-icon.svg')
        &.colorbg
          background:
            color: lighten($grey, 30%)
            image: url('../../../img/hilfe-kontakt-icon.svg')

        @include media-breakpoint-down(sm)
          height: auto
          min-height: 0

        h4
          margin-bottom: 2rem

      &.contact
        .item
          padding: 0
          .container
            height: 100%
            padding: 0
            .row
              height: 100%
              > div:first-of-type
                padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px)
              > div:last-of-type
                background:
                  position: center center
                  repeat: no-repeat
                  size: cover
                padding: 0