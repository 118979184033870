form
  .expand-hidden-field
    margin-left: 2.7rem

  button 
    background: #14aa96
    width: 100%
    padding: 9px
    color: white

  .form-control
    +font-size(1.6)
    border-radius: 0
    border: none
    padding: .5em 1em
    height: auto
  .btn.btn-secondary
    width: 100%
  a
    &.active
      color: $green
      .box
        border: 2px solid $green
    .box
      background-color: $white
      padding: 2rem
    span
      float: left
      margin-right: 1.6rem
      font-size: 3.6rem
      margin-top: -.1em
  p
    margin: $margin 0
  .form-check
    .form-check-label
      margin-bottom: $margin / 2
      padding-left: $padding / 2
    p
      margin-top: 0px
      margin-bottom: 0px

  select
    background: url(./img/angle-down.svg) no-repeat 95% center $white
    background-size: 12px
    border-radius: 0
    border: 1px solid $lightgrey
    outline: 0
    padding: .7rem 
    -webkit-appearance: none

    @-moz-document url-prefix()
      background: none

input#preferredKitten
  display: none

.registration-form-deactivated
  width: 100%

#vlb-form, #eventform
  button
    background: #14aa96
    width: 100%
    padding: 9px
    color: white
#vlb-form-en
  button
    background: #14aa96
    width: 100%
    padding: 9px
    color: white    
#vlb-form-mx
  button
    background: #14aa96
    width: 100%
    padding: 9px
    color: white  
#vlb-form-br
  button
    background: #14aa96
    width: 100%
    padding: 9px
    color: white  

