
//Styles

  @font-face
  font-family: 'ArchivoNarrowBold'
  src: url(../fonts/archivonarrow-bold-webfont.woff) format("woff")
  font-weight: 500

@font-face
  font-family: 'ArchivoNarrowRegular'
  src: url(../fonts/archivonarrow-regular-webfont.woff) format("woff")
  font-weight: 300

@font-face
  font-family: 'Glyphicons Regular'
  src: url(../fonts/glyphicons-regular.woff2) format("woff2")
  font-weight: 400

@font-face
  font-family: 'Glyphicons Halflings'
  src: url(../fonts/glyphicons-halflings-regular.woff2) format("woff2")
  font-weight: 400

.buchhandel
  scroll-behavior: smooth
  margin: 0
  font-family: Arial, Helvetica, "Helvetica Neue", sans-serif
  font-size: 14px
  line-height: 1.42857
  color: #333333
  background: none

  .glyphicons
    font-family: 'Glyphicons Regular'
    vertical-align: top

  .glyphicons:before
    display: block
    padding: 2px 0 0

  .glyphicons-search:before
    content: "\E028"

  .glyphicons-home:before
    content: "\E021"

  .glyphicons-newspaper:before
    content: "\E609"

  .glyphicons-plus-sign:before
    content: "\E191"


  .glyphicons, .halflings
    display: inline-block
    font-style: normal
    font-weight: 400
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

  h2
    font-size: 20px
    border-bottom: 1px solid #414650
    margin-top: 20px
    margin-bottom: 15px
    padding-bottom: 5px
    font-family: "ArchivoNarrowBold", sans-serif
    &.smaller
      font-size: 18px
      border-bottom: 0
      padding-bottom: 0px
      margin-bottom: 10px

  p
    margin: 0 0 10px

  .iframe-grid
    display: flex
    gap: 24px
    flex-direction: column
    @media all and (min-width: 600px)
      flex-direction: row
      justify-content: flex-start
      align-items: flex-start
      &.center
        align-items: center

    .image
      flex-shrink: 0
      max-width: 400px
      width: auto
      img
        width: 100%
        height: auto

  .bhGrid
    margin: 10px 0
    display: grid
    grid-template-columns: 1fr
    gap: 16px
    @media all and (min-width: 600px)
      grid-template-columns: 1fr 1fr

  .content-wrapper
    margin: 0 auto
    max-width: 930px
    padding: 0 15px

  .flex
    display: flex

  .items-center
    align-items: center

  .justify-center
    justify-content: center

  .flex-shrink-0
    flex-shrink: 0

  .hidden-xs
    display: none
    @media screen and (min-width: 768px)
      display: block

  form
    input
      border: 1px solid grey
    select
      border: 1px solid grey
    textarea
      border: 1px solid grey

header.bh
  position: sticky
  top: 0
  z-index: 100
  width: 100%
  @media all and (min-width: 768px)
    transition: transform .2s linear
  .content-wrapper
    height: 40px
    @media all and (min-width: 768px)
      height: 110px
  .wrapper
    background: #414650
    .logo
      @media all and (min-width: 768px)
        margin-left: 10px
        margin-right: 48px
        margin-bottom: 12px
      img
        height: 1.1rem
        @media all and (min-width: 768px)
          height: 2.8rem
  .search
    margin-left: 40px
    margin-top: 9px
  input
    height: 37px
    line-height: 37px
    font-style: italic
    font-size: 16px
    color: #a0a3a7
    padding: 0 10px
    border: 1px solid #414650
    width: 315.5px
    flex-shrink: 0
    &:focus
      outline: none
      -webkit-box-shadow: none
      box-shadow: none
      border-color: #14aa96
  button
    border: 1px solid #414650
    border-left: 0
    color: #fff
    height: 37px
    padding: 6px 12px
    line-height: 12px
    z-index: 2
    margin-left: -1px
    margin-right: 0
  &.reduced
    @media all and (min-width: 768px)
      transform: translateY(-45%)

nav.bh
  background: white
  .content-wrapper
    height: 52px
    border-bottom: 1px solid black
    @media all and (min-width: 768px)
      border-bottom: 0
      height: auto
  ul
    list-style: none
    margin: 0
    gap: 8px
    @media all and (min-width: 768px)
      padding: 10px
    li
      display: block
      width: 100%
      @media all and (min-width: 768px)
        padding: 10px 0
      a
        padding: 10px 10px
        display: block
        text-align: center
        text-decoration: none
        color: #333
        font-size: 16px
        font-family: ArchivoNarrowBold,sans-serif
        @media all and (min-width: 768px)
          padding: 10px 15px
        &:hover
          color: #14aa96
        &.active-nav
          color: #14aa96
  .nav-link-icon
    font-size: 20px
    margin-bottom: 10px

.bhForm
  input
    border: 1px solid grey
  select
    border: 1px solid grey
  textarea
    border: 1px solid grey

.back-to-top
  margin-top: 40px
  margin-bottom: 20px
  background: url('../img/pfeil_nachoben.png') 50% 0 no-repeat
  background-size: 25px
  color: #a0a3a7!important
  padding: 15px 0 0
  text-decoration: none!important
  a
    color: #a0a3a7!important
    text-decoration: none!important
  &:hover
    a
      text-decoration: underline!important

footer.bh
  padding: 20px 0
  margin-bottom: 0
  .content-wrapper
    display: flex
    gap: 16px
    flex-direction: column
    @media all and (min-width: 768px)
      flex-direction: row
      justify-content: space-between
    div p
      text-transform: uppercase
      font-weight: 700
      margin-bottom: 5px
      color: #a0a3a7!important
    ul
      padding: 0
      margin: 0
      li
        list-style-type: none
        margin-bottom: 3px
        a
          color: #a0a3a7!important
          text-decoration: none!important
        &:hover
          a
            text-decoration: underline!important
