.premiumclients
  overflow: hidden
  @media (max-width: 767px)
    margin-bottom: 2rem

  .card-img-top
    -webkit-filter: grayscale(100%) /* Safari 6.0 - 9.0 */
    filter: grayscale(100%)

  .masonry  /* Masonry container */
    columns: 3
    @include media-breakpoint-down(md)
      columns: 2
    @include media-breakpoint-down(sm)
      columns: 1

  .masonry-item
    width: 100%
    margin-bottom: 4rem

  .card
    border-radius: 0
    border: none
    display: flex
    margin-bottom: 2rem

  .line
    margin-bottom: 2rem

  .btn-secondary
    margin-bottom: 4rem

  .card-body
    padding: 1rem
    .card-title
      margin-left: 3px
    .btn
      @include media-breakpoint-down(md)
        letter-spacing: normal
      @include media-breakpoint-down(sm)
        letter-spacing: .1em
      i
        @include media-breakpoint-down(md)
          display: none
        @include media-breakpoint-down(sm)
          display: inline

  .letter-block
    margin-bottom: 4rem
    h4
      font-size: 5rem

  h3
    margin-bottom: 6rem

  .img-wrapper
    margin: 0 auto
    align-items: center
    display: flex
    flex-direction: row
    justify-content: center
    height: 8rem
    @include media-breakpoint-up(sm)
      height: 10rem

    img
      width: auto
      max-height: 46px
      max-width: 95%

.container.preisrechner
  padding-top: 4rem
  .btn-secondary
    width: auto
    display: inline-block

#pricecalculator
  #number
    background-color: $primary
    color: $white

  input
    border: none
    box-shadow: none
    outline: none
    width: 100%
    padding: 1rem 1rem

  input:disabled
    background-color: $white

  .fa-arrow-right,
  .fa-arrow-down
    color: $primary
    font-size: 3rem
    margin: 0 auto
    margin-bottom: 2rem
    cursor: pointer
    height: 1em
    &:hover
      color: lighten($primary, 20%)

  .arrowblock
    display: flex
    justify-content: center

  .input-group
    display: flex
    width: 100%
    background-color: $primary
    color: $white

    input
      padding-right: 0

    .col-1
      padding-right: 0

    .numbers
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      i
        cursor: pointer
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        vertical-align: middle
        padding-left: 0.5rem
        padding-right: 0.5rem
        background-color: $lightgreen
        @include media-breakpoint-down(md)
          width: 100%
          min-width: 3rem
