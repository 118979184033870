.lang-popup-wrapper 
  position: fixed
  inset: 0
  background: rgba(0, 0, 0, 0.7)
  transition: opacity 500ms
  visibility: visible
  z-index: 100
  opacity: 1
  display: flex
  justify-content: center
  align-items: center
  &.close
    visibility: hidden
    opacity: 0
  .lang-popup
    padding: 6rem
    background: #14AA96
    color: white
    position: relative
    width: 750px
    @include media-breakpoint-down(md)
      padding: 6rem
      width: 500px
    @include media-breakpoint-down(sm)
      padding-block: 4rem
      padding-inline: 2rem
      width: 300px
    .close-x
      background: none
      border: none
      position: absolute
      right: 1rem
      top: 1rem
      text-decoration: none
      font-size: 3.5rem
      color: white
      width: 4rem
      height: 4rem
      aspect-ratio: 1/1
      vertical-align: middle
      @include media-breakpoint-down(sm)
        right: 0rem
        top: 0rem
    .select-lang-text
      font-size: 2rem
      text-align: center
    .lang-btn-container
      margin-block: 3rem
      margin-bottom: 7rem
      display: flex
      justify-content: center
      width: 100%
      gap: 2rem
      @include media-breakpoint-down(sm)
        flex-direction: column
        max-width: 20rem
        margin-inline: auto
      button
        flex: 1 1 0
        border: solid 2px #108878
        padding-block: 1rem
        background: white
        font-weight: 500
        &:hover
          color: #14AA96
        &.active
          border: solid 3px black

    .lang-okCancle-container
      padding-inline: 6rem
      display: flex
      justify-content: center
      gap: 2rem
      @include media-breakpoint-down(md)
        padding-inline: 0rem
      #lang-popup-ok
        background: white
        font-weight: 700
        border: solid 2px transparent
      #lang-popup-cancle
        border: solid 2px white
        padding-inline: 0
      button, a
        flex: 1 1 0
        padding-block: 0.5rem
      a
        cursor: pointer
        line-height: 2