.slider
  color: $white
  margin-bottom: 0
  .row
    position: relative
    & > *:first-of-type
      padding-bottom: $margin * 4
      @include media-breakpoint-down(xs)
        padding-bottom: $margin
    .textbox
      position: absolute
      background-color: $white
      color: $default
      padding: 3rem
      @include media-breakpoint-down(md)
        position: inherit
        padding: 2rem
      &.protrude-textbox
        position: absolute!important
        width: calc(100% - 30px)
        margin-bottom: -6rem
        opacity: 0.85
    h3
      margin-bottom: $margin * 2
      @include media-breakpoint-down(md)
        margin-bottom: 0
  .owl-carousel
    z-index: 0
    .owl-stage
      display: flex
      min-height: 100%
      &::after
        display: none
      .owl-item
        display: flex
        min-height: 100%
        .item
          margin-bottom: 6rem
          min-height: 60vh
          display: flex
          align-items: flex-end
          position: relative
          width: 100%
          background:
            color: $green
            size: cover
          &.protrude-box 
            margin-bottom: 12rem  
          &.top
            background-position: top
          &.center
            background-position: center
          &.bottom
            background-position: bottom
          &.overlay
            &:before
              content: ' '
              position: absolute
              left: 0
              right: 0
              bottom: 0
              top: 0
              background-image: linear-gradient(to bottom,rgba(0,0,0,.1),rgba($default,.5))
              mix-blend-mode: normal
              z-index: 1
          &.finderslide
            position: relative
            &:before
              content: ' '
              position: absolute
              left: 0
              top: 0
              right: 0
              bottom: 0
              background-image: radial-gradient(circle, rgba(0,0,0,0.0), rgba(0,0,0,0.8) 180%)

            align-items: center
            .container, .row
              height: 100%
              .img
                padding-bottom: 0
                img
                  @include media-breakpoint-up(sm)
                    bottom: -3rem
                    max-height: 100%
                    position: absolute
                    width: auto
                  @include media-breakpoint-down(sm)
                    margin-top: 20px
                    margin-bottom: 20px
              .text
                align-self: center
                div
                  font-size: 3rem
            &.light, &.light a.btn
              color: $white
            &.dark, &.dark a.btn
              color: $default
          .btn
            @include media-breakpoint-down(sm)
              text-align: left
          .video-background    
            position: absolute
            top: 0
            video 
              width: 100vw
              height: 60vh
              object-fit: cover
        .container
          z-index: 10
    .owl-stage-outer
      //overflow: visible // Required for overflowing .textbox
    .owl-nav
      padding: 0
      background-color: yellowgreen
      @include media-breakpoint-down(md)
        display: none
      .owl-prev, .owl-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
        width: 40px
      .owl-prev
        right: 96%
        background-image: url('../../../img/pfeil-links.svg')
        background-color: transparent
      .owl-next
        left: 96%
        background-image: url('../../../img/pfeil-rechts.svg')
        background-color: transparent
    .owl-dots
      position: absolute
      bottom: 14rem
      left: 50%
      transform: translateX(-50%)
      @include media-breakpoint-down(md)
        display: none
      &.disabled
        display: block
        @include media-breakpoint-down(md)
          display: none
      .owl-dot
        &.active span
          background-color: $white
        &:focus
          outline: 0
        span
          border-radius: 0
          background-color: transparent
          border: 1px solid $white
          width: 1rem
          height: 1rem
          margin: .5rem
          @include media-breakpoint-down(sm)
            margin: 2rem 1rem
          &:hover
            background-color: $white

// bootstrap carousel stuff          
.owl-three
  button.owl-next  
    &:hover
      color: #999999  
  button.owl-prev    
    &:hover
      color: #999999
  .fa-arrow-right
    font-size: 30px
    margin-right: 20px
    &:hover
      color: #999999
  .fa-arrow-left  
    font-size: 30px
    margin-left: 20px
    &:hover
      color: #999999

.new-testimonial 
  margin: 0
  padding: 0
  .row-testimonial
    background-color: #ffffff
    margin-right: unset
    margin-left: unset
    .col-testimonial-image
      padding-left: unset
      padding-right: unset
      img 
        width: 100%
    .col-testimonial-content
      padding: 0 50px
      padding-top: 25px
      @include media-breakpoint-down(md)
        min-height: 300px
        padding-bottom: 25px
        overflow: scroll
