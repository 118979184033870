.side-navigation
  position: fixed
  left: 0
  top: 0
  bottom: 0
  width: 20%
  overflow: scroll
  @include media-breakpoint-down(md)
    width: 80%
    z-index: 100
    left: -80%
    +transition
    &.open
      left: 0


div.help-side-navigation
  background-color: #888888
  padding-top: 10px
  padding-bottom: 10px
  height: 100%

  .searchbox
    background-color: $grey
    border: 1px solid rgba($white, .5)
    color: rgba($white, .5)
    margin: 15px

    form
      .input-group-text
        background-color: transparent
        color: rgba($white, .5)
        border: 0
        font-size: 1em

      input.form-control
        background-color: transparent
        padding-left: 0
        padding-right: .75rem
        @include media-breakpoint-down(lg)
          font-size: .75em
        &:active, &:focus
          box-shadow: none
        &::placeholder
          color: rgba($white, .5)
        color: white


  span.parent-marker
    float: right
    padding-right: 15px
    margin-top: -30px
    margin-right: 1px
    padding-left: 20px

    &:hover
      cursor: pointer

  .nav-ul-depth-1
    padding-left: 0
    background:  #888888

    .nav-li-depth-1
      list-style: none

      .nav-href-depth-1
        color: #fff
        text-decoration: none
        font-weight: 900
        display: block
        padding-top: 6px
        padding-bottom: 6px
        padding-left: 15px
        font-size: 16px
        max-width: calc(100% - 35px)
        hyphens: auto

      &.active > div
        background: #14aa96


  .nav-ul-depth-2
    background: #707070
    padding-left: 0

    .nav-li-depth-2
      list-style: none

      .nav-href-depth-2
        text-decoration: none
        color: #fff
        display: block
        padding-top: 6px
        padding-bottom: 6px
        padding-left: 20px
        font-size: 14px
        max-width: calc(100% - 35px)
        hyphens: auto

      &.active > div
        background: #14aa96


  .nav-ul-depth-3
    background: #606060
    padding-left: 0


    .nav-li-depth-3
      list-style: none

      .nav-href-depth-3
        text-decoration: none
        color: #fff
        display: block
        padding-top: 6px
        padding-bottom: 6px
        padding-left: 35px
        font-size: 13px
        max-width: calc(100% - 35px)
        hyphens: auto

      &.active > div
         background: #14aa96

  .nav-ul-depth-4
    background: #484848
    display: block
    padding-left: 0

    .nav-li-depth-4
      list-style: none

      .nav-href-depth-4
        text-decoration: none
        color: #fff
        display: block
        padding-top: 6px
        padding-bottom: 6px
        padding-left: 50px
        font-size: 14px
        max-width: calc(100% - 35px)
        hyphens: auto

      &.active > div
        background: #14aa96

.sidenav
  background-color: $mediumgrey
  padding: $padding * 1.5
  width: 350px
  height: 100vh

  a
    color: $white
    text-decoration: none
    &:hover
      color: rgba($white, .5)
    span
      display: block

  .searchbox
    background-color: $grey
    border: 1px solid rgba($white, .5)
    color: rgba($white, .5)
    margin-bottom: $padding * 1.5

    form
      .input-group-text
        background-color: transparent
        color: rgba($white, .5)
        border: 0
        font-size: 1.6em
        padding: 1rem 0 1rem 1.6rem
        @include media-breakpoint-down(md)
          font-size: 1.2em
          padding: 1rem 0 1rem 1.2rem

      .input-group-prepend
        @include media-breakpoint-down(md)
          font-size: 1.2em



      input.form-control
        background-color: transparent
        &:active, &:focus
          box-shadow: none
        &::placeholder
          color: rgba($white, .5)
        color: white

  .dd-item
    padding: $padding 0
    &.visited
      background-color: $default
      margin-left: -2.4rem
      margin-right: -2.4rem
      padding-left: 2.4rem
      padding-right: 2.4rem

  ul
    font-weight: 600
    list-style-type: none
    padding: 0
    margin: 0

    &.topics
      ul
        list-style-type: none
        font-weight: 500
      li.parent ul, > li.active ul
        display: block
        > li
          padding: $padding / 1.5 $padding
        li.active
          padding: 0
        li.active > a
          background: $green
          display: block
          padding: $padding / 1.5 $padding
          position: relative
          svg
            position: absolute
            right: 1rem
            top: 1.2rem

#show-sidenav
  z-index: 10
  position: fixed
  left: 0
  top: 74%
  display: block
  padding: 1em
  background-color: #888888
  display: block
  @include media-breakpoint-up(md)
    display: none
    
.help-content
  header > nav.navbar ul.navbar-nav li a.nav-link
    white-space: nowrap
  .navbar-wrapper
    white-space: nowrap
  header nav.navbar.navbar-expand-lg .navbar-toggler
    @media (max-width: 1200px)
      display: inline-block
