.slider
  margin-bottom: $margin * 4
  color: $white

  .header-image-copyright
    position: absolute
    transform: rotate(-90deg) translate(100%, 0%)
    right: 1em
    transform-origin: right
    font-size: .8em
    bottom: 0
    @include media-breakpoint-down(md)
      bottom: 25rem

  .row
    position: relative
    & > *:first-of-type
      padding-bottom: $margin * 4
      @include media-breakpoint-down(xs)
        padding-bottom: $margin
    .textbox
      position: absolute
      bottom: 0
      background-color: $white
      color: $default
      padding: 3rem
      @include media-breakpoint-down(md)
        position: inherit
        padding: 2rem

    h3
      margin-bottom: $margin * 2
      @include media-breakpoint-down(md)
        margin-bottom: 0
  .boxslide
    .row
      margin-bottom: $margin * -2
  .boxslideColored
    position: relative
    picture
      img
        position: absolute
        object-fit: cover
        height: 100%
        width: 100%
        top: 0
  .owl-carousel
    z-index: 0
    .owl-stage
      display: flex
      height: auto !important
      height: 100%
      min-height: 100%
      .owl-item
        display: flex
        height: auto !important
        height: 100%
        min-height: 100%
        .item
          margin-bottom: $margin * 2
          min-height: 30vw
          display: flex
          align-items: flex-end
          position: relative
          width: 100%
          &.bg-color
            background-color: $lightgreen
        .finderslide
          .header-image-copyright
            position: absolute
            transform: rotate(-90deg) translate(100%, 0%)
            right: 15rem
            transform-origin: right
            @include media-breakpoint-down(md)
              bottom: 25rem
              right: 1.5rem
          &.item
            align-items: center
            .container, .row
              height: 100%
              .img
                padding-bottom: 0
                img
                  @include media-breakpoint-up(sm)
                    bottom: -25px
                    max-height: 100%
                    position: absolute
                    width: auto
                  @include media-breakpoint-down(xs)
                    margin-top: 20px
                    margin-bottom: 20px
              .text
                align-self: center
    .owl-stage-outer
      //overflow: visible // Required for overflowing .textbox
    .owl-nav
      margin: -3rem
      padding: 0
      background-color: yellowgreen
      @include media-breakpoint-down(md)
        display: none
      .owl-prev, .owl-next
        margin-top: -$margin
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
        width: 40px
      .owl-prev
        right: 96%
        background-image: url('../../../img/pfeil-links.svg')
        background-color: transparent
      .owl-next
        left: 96%
        background-image: url('../../../img/pfeil-rechts.svg')
        background-color: transparent
    .owl-dot
      &.active span
        background-color: $white
      span
        border-radius: 0
        background-color: transparent
        border: 1px solid $white
        width: 1rem
        height: 1rem
        margin: .5rem
        @include media-breakpoint-down(sm)
          margin: 2rem 1rem
        &:hover
          background-color: $white
    .owl-dots
      @include media-breakpoint-down(md)
        display: none
      &.disabled
        display: block
        @include media-breakpoint-down(md)
          display: none

#header2
  color: $white
  background-color: transparent
  .owl-carousel
    z-index: 0
    .item
      align-items: stretch
      margin-bottom: $margin * 2
      h2
        +font-size(4)
        padding-right: 8rem
        @include media-breakpoint-down(md)
          +font-size(3)
          text-shadow: 0 4px 2px rgba(0,0,0,.1)

    .box-alert
      background-color: $red
      width: 35%
      float: left
      padding: 6rem 8rem
      position: relative
      .alert-message
        position: absolute
        bottom: 6rem
        .fa-exclamation-square
          +font-size(6)
          margin-bottom: 2rem
    .box-info
      background-color: $grey
      width: 65%
      float: right
      padding: 6rem 8rem
      position: relative
      .box-message
        position: absolute
        bottom: 0
        .row
          position: relative
          padding-bottom: 2rem
          .textbox
            background-color: $white
            color: $default
            padding: 2.4rem
            position: absolute
            bottom: -2rem
            right: 8rem
            @include media-breakpoint-down(md)
              position: inherit
              padding: 2rem
            h3
              margin-bottom: 0
    .owl-stage-outer
      //overflow: visible // Required for overflowing .textbox
    .owl-nav
      display: inherit
      margin: -3rem
      padding: 0
      @include media-breakpoint-down(md)
        display: none
      .owl-prev, .owl-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
      .owl-prev
        right: 96%
      .owl-next
        left: 96%
    .owl-dot
      &.active span
        background-color: $white
      span
        border-radius: 0
        background-color: transparent
        border: 1px solid $white
        width: 1rem
        height: 1rem
        margin: .5rem
        &:hover
          background-color: $white
    .owl-dots
      @include media-breakpoint-down(sm)
        position: static
        display: none
      &.disabled
        display: block
        @include media-breakpoint-down(md)
          display: none

#header3
  color: $white
  background-color: transparent
  .owl-carousel
    z-index: 0
    .item
      align-items: stretch
      h2
        +font-size(4)
        padding-right: 8rem
        @include media-breakpoint-down(md)
          +font-size(3)
      .box-left
        background-color: $default
        width: 50%
        float: left
        padding: 6rem 8rem
        position: relative
        @include media-breakpoint-down(md)
          padding: 4rem
        @include media-breakpoint-down(xs)
          padding: 2rem
        .box-content
          position: absolute
          bottom: 6rem
          @include media-breakpoint-down(xs)
            bottom: 0
          h2
            @include media-breakpoint-down(xs)
              +font-size(2)
      .box-right
        background-color: $grey
        width: 50%
        float: right
        padding: 6rem 8rem
        position: relative
        @include media-breakpoint-down(md)
          padding: 4rem
        @include media-breakpoint-down(xs)
          padding: 2rem
        .box-content
          position: absolute
          bottom: 6rem
          @include media-breakpoint-down(xs)
            bottom: 0
          h2
            @include media-breakpoint-down(xs)
              +font-size(2)
    .owl-stage-outer
      overflow: hidden
    .owl-nav
      display: inherit
      margin: -3rem
      padding: 0
      @include media-breakpoint-down(md)
        display: none
      .owl-prev, .owl-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
      .owl-prev
        right: 96%
      .owl-next
        left: 96%
    .owl-dot
      &.active span
        background-color: $white
      span
        border-radius: 0
        background-color: transparent
        border: 1px solid $white
        width: 1rem
        height: 1rem
        margin: .5rem
        &:hover
          background-color: $white
    .owl-dots
      @include media-breakpoint-down(sm)
        position: static
        display: none
      &.disabled
        display: block
        @include media-breakpoint-down(md)
          display: none



#header4
  a
    color: $default
    text-decoration: none

    .card
      border-radius: 0
      position: relative
      border: none

    img
      height: 100%
      width: 100%

    .card-footer
      padding: 0
      background-color: transparent
      border-top: 0
      margin: $margin 0
      color: $grey

      .date
        float: left

      .comments
        float: right
        @include media-breakpoint-down(md)
          float: left
          margin-right: 1rem


    .badge
      position: absolute
      top: -32px
      right: 0
      padding: 1rem
      border-radius: 0

      &.badge-primary
        background-color: $green
        text-transform: uppercase
        font-weight: 500

.events-box
  background-color: $mediumgrey
  color: $white
  padding: 40px
  width: 320px
  h4
    font-size: 24px
    line-height: 30px
    margin-bottom: 15px
    &:first-of-type
      &:before
        content: url(./img/seminare.svg)
        margin-right: 10px
    &:last-of-type
      &:before
        content: url(./img/webinare.svg)
        margin-right: 10px
  .event
    border-bottom: 1px solid $white
    margin-bottom: 10px
    padding-bottom: 10px
    &:last-of-type
      border: none
    h5
      font-size: 18px
      font-weight: 700
      line-height: 22px
      margin-bottom: 0
      span
        font-size: 12px
        line-height: 16px
