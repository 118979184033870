.breadcrumb
  padding: 4rem 0rem .75rem 0rem
  background-color: transparent

  @include media-breakpoint-down(sm)
    padding: 1rem 0 .75rem

  .breadcrumb-item
    a
      color: #444444

  .breadcrumb-item.active
    a
      color: #e5e5e5
.content-support
  .breadcrubs
    li
      color: #6c757d
